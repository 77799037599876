<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="deviceManage">
			<template #ops>
				<el-button v-click-blur type="primary" @click="handleExportDataList">导 出</el-button>
			</template>
		</gc-header>
		<div class="run-main">
			<div class="serach">
				<gc-custom-search keyWord="设备编号" width="300px" :search.sync="form.deviceNo"></gc-custom-search>
				<gc-custom-search
					keyWord="OTA设备类型"
					width="310px"
					type="select"
					:search.sync="form.otaDeviceTypeId"
					:search-option="deviceTypeOptions"
					needAllForSearch
				></gc-custom-search>
				<gc-custom-search
					keyWord="主控板版本号"
					width="300px"
					:search.sync="form.mainControlVersion"
				></gc-custom-search>
				<gc-custom-search
					keyWord="通讯板版本号"
					width="300px"
					:search.sync="form.communicationVersion"
				></gc-custom-search>
				<gc-custom-search
					class="search"
					key-word="最后通讯时间"
					type="date"
					datePickerType="datetimerange"
					width="500px"
					:search.sync="form.communicateTime"
					valueFormat="yyyy-MM-dd HH:mm:ss"
				></gc-custom-search>
				<div class="serach-ops">
					<el-button
						type="primary"
						@click="
							pagination.currentPage = 1
							findTableList()
						"
						v-click-blur
					>
						查询
					</el-button>
					<el-button @click="handleReset" v-click-blur>重置</el-button>
				</div>
				<gc-column-setting
					storageKey="localcolumn-device-manage"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table-show">
				<gc-table
					border
					showPage
					:columns="columnList"
					:table-data="tableData"
					:current-page.sync="pagination.currentPage"
					:total="pagination.total"
					:pageSize="pagination.pageSize"
					gloabSortDisable
					pageLayout="prev, next, jumper, sizes"
					@current-page-change="currentPageChange"
					@size-change="handleSizeChange"
				></gc-table>
			</div>
		</div>
	</div>
</template>

<script>
import { apiGetDevicePageList, apiGetFirmwareDeviceType, apiDownloadOtaDevice } from '@/apis/firmware.api'
import { firmwareTypeOptions } from '@/config/const'

export default {
	name: 'otaDeviceManage',
	data() {
		return {
			form: {
				deviceNo: null,
				otaDeviceTypeId: null,
				mainControlVersion: null,
				communicationVersion: null,
				communicateTime: [],
			},
			columns: [
				{
					key: 'deviceNo',
					name: '设备编号',
					tooltip: true,
					dragDisable: true,
					disabledSelect: true,
					minWidth: 180,
				},
				{
					key: 'otaDeviceTypeName',
					name: 'OTA设备类型',
					tooltip: true,
				},
				{
					key: 'mainControlVersion',
					name: '主控板版本号',
					tooltip: true,
				},
				{
					key: 'communicationVersion',
					name: '通讯板版本号',
					tooltip: true,
				},
				{
					key: 'lastCommunicateTime',
					name: '最后通讯时间',
					minWidth: 180,
					tooltip: true,
				},
				{
					key: 'createStaffName',
					name: '创建人',
				},
				{
					key: 'createTime',
					name: '创建时间',
					minWidth: 180,
					tooltip: true,
				},
				{
					key: 'oprate',
					name: '操作',
					sortDisable: true,
					width: 130,
					fixed: 'right',
					render: (h, row) => {
						return h('div', {}, [
							h(
								'el-button',
								{
									props: {
										type: 'text',
										size: 'medium',
									},
									on: {
										click: () => {
											this.$router.push({
												path: '/firmware/device-manage/details',
												query: { deviceNo: row.deviceNo },
											})
										},
									},
								},
								'查看详情',
							),
						])
					},
				},
			],
			pagination: {
				currentPage: 1,
				pageSize: 20,
				total: 0,
			},
			tableData: [],
			loading: false,
			deviceTypeOptions: [],
			firmwareTypeOptions,
			firmwareDetails: null,
		}
	},

	created() {
		this.findFirmwareDeviceTypeOptions()
		this.findTableList()
	},

	methods: {
		async findTableList() {
			this.loading = true
			const searchParams = {
				...this.getRequestParams(),
				current: this.pagination.currentPage,
				size: this.pagination.pageSize,
			}
			try {
				const { records = [], total } = await apiGetDevicePageList(searchParams)
				this.tableData = records
				this.pagination.total = total
			} finally {
				this.loading = false
			}
		},

		async findFirmwareDeviceTypeOptions() {
			const data = await apiGetFirmwareDeviceType(this.tenantId)
			this.deviceTypeOptions = (data || []).map(item => {
				return {
					value: item.otaDeviceType,
					label: item.otaDeviceTypeName,
				}
			})
		},

		getRequestParams() {
			const communicateTime = this.form.communicateTime
			return {
				...this.form,
				startCommunicateTime: communicateTime ? communicateTime[0] : null,
				endCommunicateTime: communicateTime ? communicateTime[1] : null,
				communicateTime: undefined,
			}
		},

		async handleExportDataList() {
			if (!this.tableData.length) {
				return this.$message.warning('暂无表格数据')
			}
			const searchParams = this.getRequestParams()
			const data = await apiDownloadOtaDevice(searchParams)
			const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
			const link = document.createElement('a')
			link.style.display = 'none'
			link.href = URL.createObjectURL(blob)
			link.download = 'ota设备列表'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},

		currentPageChange(current) {
			this.pagination.currentPage = current
			this.findTableList()
		},

		handleSizeChange(size) {
			this.pagination.currentPage = 1
			this.pagination.pageSize = size
			this.findTableList()
		},

		handleReset() {
			for (const key in this.form) {
				this.form[key] = null
			}
			this.pagination.currentPage = 1
			this.findTableList()
		},
	},

	computed: {
		columnList() {
			return this.columns.filter(o => o.selected)
		},

		tenantId() {
			return this.$store.getters.userInfo.tenantId || null
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../run-manage/runLayout.scss';
</style>
